import { withRouteGuard } from "@/utils/routeGuards";

const moduleTitle = "module_title.work_order";

const workOrderRoutes = {
  path: "work-order",
  title: moduleTitle,
  sidebarProps: {
    key: "1-2",
    iconSrc: "work-order",
    label: moduleTitle,
    link: "/work-order",
    groupingType: "TOOLS",
    order: 2,
  },
  element: null,
  children: [
    {
      index: true,
      path: "",
      title: moduleTitle,
      element: withRouteGuard(() => import("@/pages/work-order/WorkOrderList")),
    },
    {
      path: ":id",
      title: moduleTitle,
      headerProps: {
        backUrl: "/work-order",
      },
      element: withRouteGuard(
        () => import("@/pages/work-order/WorkOrderDetails")
      ),
    },
  ],
};

export default workOrderRoutes;

import { withRouteGuard } from "@/utils/routeGuards";

const moduleTitle = "module_title.dashboard";

const dashboardRoutes = {
  path: "dashboard",
  title: moduleTitle,
  sidebarProps: {
    key: "1-1",
    iconSrc: "dashboard",
    label: moduleTitle,
    link: "/dashboard",
    groupingType: "TOOLS",
    order: 1,
  },
  element: withRouteGuard(() => import("@/pages/dashboard/Dashboard")),
};

export default dashboardRoutes;

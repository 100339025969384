import { withRouteGuard } from "@/utils/routeGuards";

const accommodationRentalRoutes = {
  path: "accommodation-rental",
  title: "module_title.accommodation_rental_management",
  sidebarProps: {
    key: "1-8",
    iconSrc: "accommodation-rental",
    label: "module_title.accommodation_rental",
    link: "/accommodation-rental",
    groupingType: "TOOLS",
    order: 8,
  },
  element: withRouteGuard(
    () => import("@/pages/accommodation-rental/AccommRentalList")
  ),
};

export default accommodationRentalRoutes;

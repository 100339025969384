import { withRouteGuard } from "@/utils/routeGuards";

const workflowSetupRoutes = {
  path: "workflow-setup",
  title: "module_title.settings",
  sidebarProps: {
    key: "2-2",
    iconSrc: "workflow-setup",
    label: "module_title.workflow_setup",
    link: "/workflow-setup",
    groupingType: "SETTINGS",
    order: 2,
  },
  element: withRouteGuard(
    () => import("@/pages/workflow-setup/WorkflowSetupList")
  ),
};

export default workflowSetupRoutes;

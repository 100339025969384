import { useApi } from "@/utils/Request";

export const useUserApi = () => {
  const [api] = useApi();

  const getUserProfile = (uuid) =>
    api({
      url: "/user/" + uuid,
      method: "get",
    });

  const updateProfile = (data) =>
    api({
      url: "/user/update-profile",
      method: "post",
      data: data,
    });

  const updatePreferredLanguage = (data) =>
    api({
      url: "/user/update-preferred-language",
      method: "post",
      data: data,
    });

  const getUserList = (data) =>
    api({
      url: "/user/list",
      method: "post",
      data: data,
    });

  const createUser = (data) =>
    api({
      url: "/user/create",
      method: "post",
      data: data,
    });

  const updateUser = (data) =>
    api({
      url: "/user/update",
      method: "post",
      data: data,
    });

  const updateLevel = (data) =>
    api({
      url: "/user-role/update-level",
      method: "post",
      data: data,
    });

  return {
    getUserProfile,
    updateProfile,
    updatePreferredLanguage,
    getUserList,
    createUser,
    updateUser,
    updateLevel,
  };
};

import { withNestedRouteGuard } from "../../utils/routeGuards";

const moduleTitle = "module_title.settings";

const dropdownListRoutes = {
  path: "dropdown-list",
  title: moduleTitle,
  sidebarProps: {
    key: "2-1",
    iconSrc: "dropdown-list",
    label: "module_title.dropdown_list",
    link: "/dropdown-list/human-resource-management",
    groupingType: "SETTINGS",
    order: 1,
  },
  element: null,
  children: [
    {
      index: true,
      path: "",
      title: moduleTitle,
      element: withNestedRouteGuard(
        () => import("@/pages/dropdown-list/DropdownList"),
        () =>
          import(
            "@/pages/dropdown-list/human-resource-management/HumanResourceMgmtSettings"
          )
      ),
    },
    {
      path: "human-resource-management",
      title: moduleTitle,
      element: withNestedRouteGuard(
        () => import("@/pages/dropdown-list/DropdownList"),
        () =>
          import(
            "@/pages/dropdown-list/human-resource-management/HumanResourceMgmtSettings"
          )
      ),
    },
    {
      path: "company-management",
      title: moduleTitle,
      element: withNestedRouteGuard(
        () => import("@/pages/dropdown-list/DropdownList"),
        () =>
          import("@/pages/dropdown-list/company-management/CompanyMgmtSettings")
      ),
    },
    {
      path: "rental-management",
      title: moduleTitle,
      element: withNestedRouteGuard(
        () => import("@/pages/dropdown-list/DropdownList"),
        () =>
          import("@/pages/dropdown-list/rental-management/RentalMgmtSettings")
      ),
    },
  ],
};

export default dropdownListRoutes;

import { withRouteGuard } from "@/utils/routeGuards";

const officeRentalRoutes = {
  path: "office-rental",
  title: "module_title.office_rental_management",
  sidebarProps: {
    key: "1-7",
    iconSrc: "office-rental",
    label: "module_title.office_rental",
    link: "/office-rental",
    groupingType: "TOOLS",
    order: 7,
  },
  element: withRouteGuard(
    () => import("@/pages/office-rental/OfficeRentalList")
  ),
};

export default officeRentalRoutes;

import { Spin } from "antd";
import { Suspense, lazy } from "react";
import styled from "styled-components";

const RawLazyLoadWrapper = ({ className, importFn, children }) => {
  const ReactChild = lazy(importFn);

  const fallback = <Spin className={`${className} page-spinner`} spinning />;

  return (
    <Suspense fallback={fallback}>
      <ReactChild>{children}</ReactChild>
    </Suspense>
  );
};

const LazyLoadWrapper = styled(RawLazyLoadWrapper)`
  &.page-spinner {
    width: 100%;
    height: calc(100% - 8rem);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .ant-spin-dot.ant-spin-dot-spin {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

export default LazyLoadWrapper;

import { useApi } from "@/utils/Request";

export const useAuthApi = () => {
  const [api] = useApi();

  const login = (authData) => {
    const encodedAuthData = window.btoa(
      authData.email + ":" + authData.password
    );

    return api({
      url: "/user/login",
      method: "post",
      headers: {
        Authorization: "Basic " + encodedAuthData,
      },
      data: {},
    });
  };

  const logout = () => {
    return api({
      url: "/user/logout",
      method: "post",
      // post must have data (even it is empty) to avoid Content-Type being deleted (HTTP error 415)
      data: {},
    });
  };

  const resetPassword = (data) => {
    return api({
      url: "/user/reset-password",
      method: "post",
      data: data,
    });
  };

  const changePassword = (data) => {
    return api({
      url: "/user/change-password",
      method: "post",
      data: data,
    });
  };

  return {
    login,
    logout,
    resetPassword,
    changePassword,
  };
};

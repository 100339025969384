import { useState, cloneElement, useEffect } from "react";
import styled from "styled-components";
import { Flex, Dropdown, theme, Button } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import SvgIcon from "@/components/SvgIcon";
import { authActions } from "@/store/slices/auth";
import { useNavigate, useLocation } from "react-router-dom";

const RawBoHeader = (props) => {
  const { className, setIsNotifDrawerOpen = () => {} } = props || {};

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // Antd theme token
  const { useToken } = theme;
  const { token } = useToken();
  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };
  const menuStyle = {
    boxShadow: "none",
  };

  const [haveNotification, setHaveNotification] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const currentRouteObj = useSelector((state) => state.app.currentRouteObj);
  const userProfile = useSelector((state) => state.auth.profileObj);

  const notificationItems = () => {
    return [
      {
        key: "1",
        label: (
          <Flex justify="start" align="center" className="notification">
            <SvgIcon src="notification" />
            <div className="notification-desc">
              <h5 className="notification-title">Annual Agreement Charges</h5>
              <p className="notification-first-content">
                <span className="company-name">LOLD Holdings </span>
                due 30 Jul 2024
              </p>
              <p className="notification-second-content">
                1h ago &#183; Company
              </p>
            </div>
          </Flex>
        ),
      },
    ];
  };

  const profileItems = [
    {
      key: "company-profile",
      label: (
        <p onClick={() => navigate("/profile")}>{t("general.edit_profile")}</p>
      ),
    },
    {
      key: "sign-out",
      label: (
        <p onClick={() => dispatch(authActions.logout())}>
          {t("general.sign_out")}
        </p>
      ),
    },
  ];

  return (
    <div id="bo-header" className={className}>
      <Flex className="header-content" justify="space-between" align="center">
        <Flex align="center" gap={8}>
          {!!currentRouteObj?.headerProps?.backUrl && (
            <SvgIcon
              src="backward"
              className="pointer"
              onClick={() => navigate(currentRouteObj?.headerProps?.backUrl)}
            />
          )}
          <h1 className={"page-title " + pathname.split("/")[1]}>
            {t(currentRouteObj.title)}
          </h1>
          <div id="header-tag-container" />
        </Flex>

        <Flex justify="space-between" align="center" gap="large">
          {pathname === "/dashboard" && (
            <Dropdown
              placement="bottom"
              overlayClassName="header-notification-dropdown"
              menu={{ items: notificationItems() }}
              dropdownRender={(menu) => (
                <div className="dropdown-container" style={contentStyle}>
                  <Flex
                    className="dropdown-header"
                    align="center"
                    justify="space-between"
                  >
                    <h3 className="dropdown-title">
                      {t("general.notifications")}
                    </h3>
                    <Button
                      className="view-button custom-button no-outline"
                      onClick={() =>
                        setIsNotifDrawerOpen((prevState) => !prevState)
                      }
                    >
                      {t("button.view_all")}
                    </Button>
                  </Flex>
                  {cloneElement(menu, {
                    style: menuStyle,
                  })}
                </div>
              )}
            >
              <div className="notification-dropdown">
                <SvgIcon
                  src={haveNotification ? "notification-bing" : "notification"}
                />
              </div>
            </Dropdown>
          )}
          <Dropdown
            trigger={["click"]}
            placement="bottomLeft"
            overlayClassName="header-profile-dropdown"
            menu={{ items: profileItems }}
            onOpenChange={(open) => setIsDropdownOpen(open)}
          >
            <Flex className="profile" justify="space-between" align="center">
              <div className="profile-desc">
                <h3 className="profile-name">{userProfile.name}</h3>
                <p className="profile-role">
                  {userProfile.userRole?.role?.name}
                </p>
              </div>
              <SvgIcon
                src={
                  isDropdownOpen
                    ? "dropdown-circle-open"
                    : "dropdown-circle-close"
                }
              />
            </Flex>
          </Dropdown>
        </Flex>
      </Flex>
    </div>
  );
};

const BoHeader = styled(RawBoHeader)`
  height: var(--bo-header-height);
  padding-inline: var(--bo-spacing-inline) 16px;
  position: fixed;
  background-color: #01010b !important;
  width: -webkit-fill-available;
  z-index: 5 !important;

  .header-content {
    margin-top: 12px;
  }

  .page-title {
    margin-bottom: 0;
    font: normal normal 600 var(--font-size-l) var(--default-font);
    color: #fbfbfb;

    &.dashboard,
    &.dropdown-list,
    &.workflow-setup {
      color: #a1a9b8 !important;
    }
  }

  .header-counting-tag {
    white-space: pre;

    &.user {
      background-color: var(--user-color);
    }
    &.company {
      background-color: var(--company-color);
    }
    &.human-resource {
      background-color: var(--human-resource-color);
    }
    &.office-rental {
      background-color: var(--office-rental-color);
    }
    &.accommodation-rental {
      background-color: var(--accommodation-rental-color);
    }
    &.permit {
      background-color: var(--permit-color);
    }
    &.telco-services {
      background-color: var(--telco-services-color);
    }
    &.facilities {
      background-color: var(--facilities-color);
    }
    &.utilities {
      background-color: var(--utilities-color);
    }
  }

  .notification-dropdown {
    border-radius: 8px;

    :hover {
      background-color: var(--base-color) !important;
    }
  }

  .profile {
    padding: 4px 16px;
    border-radius: 8px;
    cursor: pointer;

    :hover {
      background-color: var(--default-border-color);
    }

    &-desc {
      margin-right: 1rem;
    }

    &-name {
      font: normal normal 600 var(--font-size-s) var(--default-font);
      color: #808ea1;
    }

    &-role {
      font: normal normal 500 var(--font-size-xs) var(--default-font);
      color: #e5ecf6;
    }
  }
`;

export const useHeaderTagContainerEl = () => {
  const [headerTagContainerEl, setHeaderTagContainerEl] = useState(null);

  useEffect(() => {
    setHeaderTagContainerEl(document.getElementById("header-tag-container"));
  }, []);

  return {
    headerTagContainerEl,
  };
};

export default BoHeader;

import { withRouteGuard } from "@/utils/routeGuards";

const moduleTitle = "module_title.human_resource_management";

const humanResourceRoutes = {
  path: "human-resource",
  title: moduleTitle,
  sidebarProps: {
    key: "1-6",
    iconSrc: "human-resource",
    label: "module_title.human_resource",
    link: "/human-resource",
    groupingType: "TOOLS",
    order: 6,
  },
  element: null,
  children: [
    {
      index: true,
      path: "",
      title: moduleTitle,
      element: withRouteGuard(
        () => import("@/pages/human-resource/HumanResourceList")
      ),
    },
    {
      path: ":id",
      title: moduleTitle,
      headerProps: {
        backUrl: "/human-resource",
      },
      element: withRouteGuard(
        () => import("@/pages/human-resource/HumanResourceDetails")
      ),
    },
  ],
};

export default humanResourceRoutes;

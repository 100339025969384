import { createBrowserRouter, RouterProvider } from "react-router-dom";
import values from "lodash/values";

import PublicLayout from "@/layouts/PublicLayout";
import ErrorPage from "@/pages/error-page.js";

// Import all modules dynamically
const importAllModules = (context) => {
  const modules = {};
  context.keys().forEach((modulePath) => {
    const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, "$1");
    modules[moduleName] = context(modulePath).default;
  });
  return modules;
};

// Load modules from the 'modules' directory
const modulesContext = require.context("./modules", true, /\.js$/);
const modules = importAllModules(modulesContext);

const moduleRoutes = values(modules);
const errorRoute = {
  path: "*",
  element: (
    <PublicLayout>
      <ErrorPage type="404" />
    </PublicLayout>
  ),
};
moduleRoutes.push(errorRoute);

const router = createBrowserRouter(moduleRoutes);

const Router = () => <RouterProvider router={router} />;

export default Router;
export const allRoutes = moduleRoutes;

import { withRouteGuard, withNestedRouteGuard } from "@/utils/routeGuards";

const moduleTitle = "module_title.company_management";

const companyRoutes = {
  path: "company",
  title: moduleTitle,
  sidebarProps: {
    key: "1-5",
    iconSrc: "company",
    label: "module_title.company",
    link: "/company",
    groupingType: "TOOLS",
    order: 5,
  },
  element: null,
  children: [
    {
      index: true,
      path: "",
      element: withRouteGuard(() => import("@/pages/company/CompanyList")),
    },
    {
      path: "CompanyList",
      title: moduleTitle,
      element: withRouteGuard(() => import("@/pages/company/CompanyList")),
    },
    {
      path: ":id",
      title: moduleTitle,
      children: [
        {
          index: true,
          path: "",
          headerProps: {
            backUrl: "/company/CompanyList",
          },
          element: withNestedRouteGuard(
            () => import("@/pages/company/CompanyInfo"),
            () => import("@/pages/company/CompanyInfo")
          ),
        },
        {
          path: "company-details",
          title: moduleTitle,
          headerProps: {
            backUrl: "/company/CompanyList",
          },
          element: withNestedRouteGuard(
            () => import("@/pages/company/CompanyInfo"),
            () => import("@/pages/company/company-details/CompanyDetails")
          ),
        },
        {
          path: "employee",
          title: moduleTitle,
          headerProps: {
            backUrl: "/company/CompanyList",
          },
          element: withNestedRouteGuard(
            () => import("@/pages/company/CompanyInfo"),
            () => import("@/pages/company/employee/CompanyEmployeeList")
          ),
        },
        {
          path: "office-rental",
          title: moduleTitle,
          headerProps: {
            backUrl: "/company/CompanyList",
          },
          element: withNestedRouteGuard(
            () => import("@/pages/company/CompanyInfo"),
            () =>
              import("@/pages/company/office-rental/CompanyOfficeRentalList")
          ),
        },
        {
          path: "accommodation-rental",
          title: moduleTitle,
          headerProps: {
            backUrl: "/company/CompanyList",
          },
          element: withNestedRouteGuard(
            () => import("@/pages/company/CompanyInfo"),
            () =>
              import(
                "@/pages/company/accommodation-rental/CompanyAccommRentalList"
              )
          ),
        },
        {
          path: "permit",
          title: moduleTitle,
          headerProps: {
            backUrl: "/company/CompanyList",
          },
          element: withNestedRouteGuard(
            () => import("@/pages/company/CompanyInfo"),
            () => import("@/pages/company/permit/CompanyPermitList")
          ),
        },
        {
          path: "telco-services",
          title: moduleTitle,
          headerProps: {
            backUrl: "/company/CompanyList",
          },
          element: withNestedRouteGuard(
            () => import("@/pages/company/CompanyInfo"),
            () =>
              import("@/pages/company/telco-services/CompanyTelcoServicesList")
          ),
        },
        {
          path: "facilities",
          title: moduleTitle,
          headerProps: {
            backUrl: "/company/CompanyList",
          },
          element: withNestedRouteGuard(
            () => import("@/pages/company/CompanyInfo"),
            () => import("@/pages/company/facilities/CompanyFacilitiesList")
          ),
        },
        {
          path: "utilities",
          title: moduleTitle,
          headerProps: {
            backUrl: "/company/CompanyList",
          },
          element: withNestedRouteGuard(
            () => import("@/pages/company/CompanyInfo"),
            () => import("@/pages/company/utilities/CompanyUtilitiesList")
          ),
        },
        {
          path: "commission-calculation",
          title: moduleTitle,
          headerProps: {
            backUrl: "/company/CompanyList",
          },
          element: withNestedRouteGuard(
            () => import("@/pages/company/CompanyInfo"),
            () =>
              import(
                "@/pages/company/commission-calculation/CompanyCommissionCalculation"
              )
          ),
        },
      ],
    },
  ],
};

export default companyRoutes;

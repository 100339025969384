import { withRouteGuard } from "@/utils/routeGuards";

const officeRentalRoutes = {
  path: "permit",
  title: "module_title.permit_management",
  sidebarProps: {
    key: "1-9",
    iconSrc: "permit",
    label: "module_title.permit",
    link: "/permit",
    groupingType: "TOOLS",
    order: 9,
  },
  element: withRouteGuard(() => import("@/pages/permit/PermitList")),
};

export default officeRentalRoutes;

import { useEffect, useState } from "react";
import styled from "styled-components";
import BoHeader from "./_components/BoHeader";
import BoSidebar from "./_components/BoSidebar";
import BoNotification from "./_components/BoNotification";
import { useLocation } from "react-router-dom";
import { appActions } from "@/store/slices/app";
import { useDispatch, useSelector } from "react-redux";
import { allRoutes } from "@/router";
import { useCustomMediaQuery } from "@/hooks";
import { useUserApi } from "@/api/user";
import { authActions } from "@/store/slices/auth";

const flatMapRoute = ({ children, title, path, headerProps }) => {
  if (children?.length) {
    return children
      .map(({ path: childPath, ...rest }) => ({
        ...rest,
        path: `${path}/${childPath}`,
      }))
      .flatMap((child) =>
        child.index ? { title, path, headerProps } : flatMapRoute(child)
      );
  }
  return { title, path, headerProps };
};

const generateRegexWithId = (inputString) => {
  const idPattern = "([a-zA-Z0-9-]+)"; //Accept alphanumeric and hyphen
  const escapedString = inputString.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  const regexString = escapedString.replace(/:id/, idPattern);
  return new RegExp(`^${regexString}$`);
};

const RawBoLayout = ({ children, className }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { isMobile } = useCustomMediaQuery();

  const [isNotifDrawerOpen, setIsNotifDrawerOpen] = useState(false);
  const [isSideBarExpanded, setIsSideBarExpanded] = useState(false);

  const profileUuid = useSelector((state) => state.auth.uuid);

  const { getUserProfile } = useUserApi();

  useEffect(() => {
    getAdminProfile();
  }, []);

  useEffect(() => {
    const allPossibleRoutes = allRoutes.flatMap(flatMapRoute);

    const matchedRoute = allPossibleRoutes.find((route) => {
      if (`/${route.path}` === location.pathname) {
        return route;
      }

      const splittedCurrPath = location.pathname.split("/");
      const targetPath = splittedCurrPath.slice(1).join("/");
      const regex = generateRegexWithId(route.path);

      if (regex.test(targetPath)) {
        return route;
      }

      return false;
    });

    dispatch(
      appActions.setCurrentRouteObj({
        title: matchedRoute.title,
        path: matchedRoute.path,
        headerProps: matchedRoute.headerProps,
      })
    );
  }, [location.pathname]);

  const getAdminProfile = async () => {
    if (!profileUuid) return;

    try {
      const res = await getUserProfile(profileUuid);
      if (res.status !== "ok") return;

      dispatch(authActions.setProfileObj(res.result));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div id="bo-wrapper" className={`${className} ${isMobile ? "mobile" : ""}`}>
      <div
        onMouseEnter={() => setIsSideBarExpanded(true)}
        onMouseLeave={() => setIsSideBarExpanded(false)}
      >
        <BoSidebar isExpanded={isSideBarExpanded} />
      </div>

      <div
        id="bo-container"
        className={isSideBarExpanded ? "sidebar-expanded" : ""}
      >
        <BoHeader setIsNotifDrawerOpen={setIsNotifDrawerOpen} />
        <section id="bo-content">{children}</section>
      </div>

      <BoNotification
        isNotifDrawerOpen={isNotifDrawerOpen}
        setIsNotifDrawerOpen={setIsNotifDrawerOpen}
      />
    </div>
  );
};

const BoLayout = styled(RawBoLayout)`
  height: 100%;
  width: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: flex;

  #bo-container {
    width: 100%;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    margin-left: 4.5rem;
    transition: margin-left 0.3s ease-out;
  }

  .sidebar-expanded {
    margin-left: 18.19rem !important;
  }

  #bo-content {
    padding: 86px var(--bo-spacing-inline) 16px var(--bo-spacing-inline);
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
`;

export default BoLayout;

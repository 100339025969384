// General
export const API_URL = process.env.REACT_APP_API_URL;
export const MOBILE_MAX_WIDTH = 992; // refer to Bootstrap's responsive design
export const PAGE_SIZE = [10, 20, 50, 100, 200];
export const TIME_FORMAT = "hh:mm A";
export const DATE_FORMAT = "DD/MM/YYYY";

// Ant Design UI
export const NOTIFY_POSITION = "bottomRight";
export const NOTIFY_DURATION = 5; // seconds

// Code for identification
export const DEFAULT_TIMEOUT = 120000; // 2mins
export const EXTENDED_TIMEOUT = 1200000; // 20mins
export const NO_TIMEOUT = 0;

// Localization
export const DEFAULT_LANG = "en";
export const LANGUAGE_OPTION = [
  {
    value: "en",
    label: "general.lang_en",
  },
  {
    value: "zh",
    label: "general.lang_zh",
  },
];

// Misc.
export const ALL_FILE_TYPE = {
  IMAGE: ".tif, .tiff, .bmp, .jpg, .jpeg, .gif, .png",
  EXCEL: ".csv, .xlsx, .xls",
  DOC: ".doc, .docx, .pdf",
};

export const ALL_FILE_TYPE_SVG = [
  "tif",
  "tiff",
  "bmp",
  "jpg",
  "jpeg",
  "gif",
  "png",
  "csv",
  "xlsx",
  "xls",
  "doc",
  "docx",
  "pdf",
];

export const ADD_STATUS = [
  {
    value: "ACTIVE",
    label: "status.active",
  },
  {
    value: "INACTIVE",
    label: "status.inactive",
  },
];

export const EDIT_STATUS = [
  {
    value: "ACTIVE",
    label: "status.active",
  },
  {
    value: "INACTIVE",
    label: "status.inactive",
  },
  {
    value: "ARCHIVED",
    label: "status.archived",
  },
];

export const GENDER_LIST = [
  { label: "constant.male", value: "male" },
  { label: "constant.female", value: "female" },
];

export const USER_SUB_ROLE = [
  { label: "user.requester", value: "requester" },
  { label: "user.reviewer", value: "reviewer" },
  { label: "user.approver", value: "approver" },
];

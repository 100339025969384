import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { authSelectors } from "@/store/slices/auth";
import BoLayout from "@/layouts/BoLayout";
import LazyLoadWrapper from "@/router/LazyLoadWrapper";

export const RouteGuard = ({
  children,
  mustAuthenticated = false,
  mustNotAuthenticated = false,
}) => {
  const [showChildren, setShowChildren] = useState(false);

  const navigate = useNavigate();
  const prevLocation = useLocation();
  const isLoggedIn = useSelector(authSelectors.selectIsLoggedIn);
  const searchParams = new URLSearchParams(window.location.search);
  const queryString = searchParams.get("redirectTo");
  const tabType = searchParams.get("type");

  useEffect(() => {
    setShowChildren(false);

    if (mustAuthenticated && !isLoggedIn) {
      const redirectPath = tabType
        ? `/sign-in?redirectTo=${encodeURIComponent(prevLocation.pathname)}&type=${tabType}`
        : `/sign-in?redirectTo=${encodeURIComponent(prevLocation.pathname)}`;
      navigate(redirectPath);
    } else if (mustNotAuthenticated && isLoggedIn) {
      const redirectPath = queryString ? decodeURIComponent(queryString) : "/";
      navigate(redirectPath);
    } else {
      setShowChildren(true);
    }
  }, [
    isLoggedIn,
    mustAuthenticated,
    mustNotAuthenticated,
    prevLocation.pathname,
    queryString,
    tabType,
  ]);

  return <>{!!showChildren && children}</>;
};

// Single Route Guard Wrapper
export const withRouteGuard = (importFn) => {
  return (
    <BoLayout>
      <RouteGuard mustAuthenticated>
        <LazyLoadWrapper importFn={importFn} />
      </RouteGuard>
    </BoLayout>
  );
};

// Nested Route Guard Wrapper
export const withNestedRouteGuard = (importFn, nestedImportFn) => {
  return (
    <BoLayout>
      <RouteGuard mustAuthenticated>
        <LazyLoadWrapper importFn={importFn}>
          <LazyLoadWrapper importFn={nestedImportFn} />
        </LazyLoadWrapper>
      </RouteGuard>
    </BoLayout>
  );
};

const routeGuards = { RouteGuard, withRouteGuard, withNestedRouteGuard };

export default routeGuards;

import styled from "styled-components";
import SvgIcon from "@/components/SvgIcon";
import { Flex } from "antd";

const RawBoNotification = (props) => {
  const {
    className,
    isNotifDrawerOpen = false,
    setIsNotifDrawerOpen = () => {},
  } = props || {};

  return (
    <div className={className}>
      <div
        id="bo-notification"
        className={`bo-notification ${isNotifDrawerOpen ? "open" : ""}`}
      >
        <Flex justify="space-between" align="center">
          <h1>Notification Section</h1>
          <SvgIcon
            src="cross"
            onClick={() => setIsNotifDrawerOpen((prevState) => !prevState)}
          />
        </Flex>
        <Flex justify="start" align="center" className="notification">
          <SvgIcon src="notification" />
          <div className="notification-desc">
            <h5 className="notification-title">Annual Agreement Charges</h5>
            <p className="notification-first-content">
              <span className="company-name">LOLD Holdings </span>
              due 30 Jul 2024
            </p>
            <p className="notification-second-content">1h ago &#183; Company</p>
          </div>
        </Flex>
      </div>
    </div>
  );
};

const BoNotification = styled(RawBoNotification)`
  .bo-notification {
    background-color: #10111b;
    width: 0px;
    top: 0;
    bottom: 0;
    transition: width 0.8s;
    border-left: 1px solid var(--default-border-color);
  }

  .bo-notification.open {
    width: 389px;
  }

  .notification {
    &-desc {
      margin-left: 0.5rem !important;
      display: grid;
      gap: 0.2rem;
    }
    &-title {
      font: normal normal 500 var(--font-size-m) var(--default-font) !important;
      margin-bottom: 0;
    }
    &-first-content {
      span {
        color: #a7a7cc;
      }
    }
  }
`;

export default BoNotification;

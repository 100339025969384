import { withRouteGuard } from "@/utils/routeGuards";

const userRoutes = {
  path: "user",
  title: "module_title.user_management",
  sidebarProps: {
    key: "1-4",
    iconSrc: "user",
    label: "module_title.user",
    link: "/user",
    groupingType: "TOOLS",
    order: 4,
  },
  element: withRouteGuard(() => import("@/pages/user/UserList")),
};

export default userRoutes;

import { withRouteGuard } from "@/utils/routeGuards";

const facilitiesRoutes = {
  path: "facilities",
  title: "module_title.facilities_management",
  sidebarProps: {
    key: "1-11",
    iconSrc: "facilities",
    label: "module_title.facilities",
    link: "/facilities",
    groupingType: "TOOLS",
    order: 11,
  },
  element: withRouteGuard(() => import("@/pages/facilities/FacilitiesList")),
};

export default facilitiesRoutes;

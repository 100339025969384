import { withRouteGuard } from "@/utils/routeGuards";

const utilitiesRoutes = {
  path: "utilities",
  title: "module_title.utilities_management",
  sidebarProps: {
    key: "1-12",
    iconSrc: "utilities",
    label: "module_title.utilities",
    link: "/utilities",
    groupingType: "TOOLS",
    order: 12,
  },
  element: withRouteGuard(() => import("@/pages/utilities/UtilitiesList")),
};

export default utilitiesRoutes;

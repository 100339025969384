import { withRouteGuard } from "@/utils/routeGuards";

const telcoServicesRoutes = {
  path: "telco-services",
  title: "module_title.telco_services_management",
  sidebarProps: {
    key: "1-10",
    iconSrc: "telco-services",
    label: "module_title.telco_services",
    link: "/telco-services",
    groupingType: "TOOLS",
    order: 10,
  },
  element: withRouteGuard(
    () => import("@/pages/telco-services/TelcoServicesList")
  ),
};

export default telcoServicesRoutes;
